import { computed } from 'vue'

import type { Image } from '@backmarket/http-api/src/api-specs-content'
import { getCategories } from '@backmarket/http-api/src/api-specs-model-comparison'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import type { Location } from '@ds/types/Location'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'

import { useCurrentCategory } from '../../composables/useCurrentCategory'
import { MODELS_COMPARISON } from '../../routes-names'

export type ModelsComparisonCategory = {
  image: Image
  title: string
  trackingName: string
  link: Location
}
export function useFetchSuggestedCategories() {
  const { data } = useHttpFetch(getCategories)
  const resolveLocalizedRoute = useRouteLocationWithLocale()

  const suggestedCategories = computed<ModelsComparisonCategory[]>(() => {
    const allCategories = data.value === null ? [] : data.value.categories

    const currentCategory = useCurrentCategory()

    return allCategories
      .filter(({ name }) => name !== currentCategory)
      .map((category) => ({
        image: category.image,
        title: category.label,
        link: resolveLocalizedRoute({
          type: 'internal',
          name: MODELS_COMPARISON.CATEGORY,
          params: { category: category.name },
        }),
        trackingName: category.name,
      }))
  })

  const hasSuggestedCategories = computed(
    () => suggestedCategories.value?.length > 0,
  )

  return { suggestedCategories, hasSuggestedCategories }
}
